import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { UserblockService } from './userblock.service';
import { AuthenticationService } from '../../../core/authentication/authentication.service';
import { SettingsService } from '../../../core/settings/settings.service';

@Component({
    selector: 'app-userblock',
    templateUrl: './userblock.component.html',
    styleUrls: ['./userblock.component.scss']
})
export class UserblockComponent implements OnInit {
    user: any;
    constructor(public userblockService: UserblockService, private auth: AuthenticationService, public router : Router, private settings: SettingsService) {

        this.user = {
            picture: 'assets/img/user/11.jpg',
            name: this.settings.getUserSetting('name')
        };
    }

    ngOnInit() {
    }

    userBlockIsVisible() {
        return this.userblockService.getVisibility();
    }

    onLogout(event){
        event.preventDefault();
        this.auth.logout()
        .then((logout :boolean) => {
            this.userblockService.toggleVisibility();
            this.router.navigate(['login']);
        });
    };

}
