import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'valoresFiltrados',
})
export class ValoresFiltradosPipe implements PipeTransform {

  transform(value: any, index, tipo, valores, ...args: any[]): any {
    if(
      valores != undefined &&
      valores.length > 0 &&
      valores[0].subtipos != undefined
    ){
      let filtrado = [];
      valores.forEach(element => {
        element.subtipos.forEach(subelement => {
          if( subelement.id == tipo )
            filtrado = subelement.valores;
        });
      });
      return filtrado;
    }

    if(tipo == 0) return [];
    else {
      const valor = valores.find((e) => {return e.id == tipo} );
      return valor != undefined ? valor.valores : [];
    }
  }

}
