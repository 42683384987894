import { Injectable } from '@angular/core';
import { Router, CanActivate, CanActivateChild, CanLoad, ActivatedRouteSnapshot, RouterStateSnapshot  } from '@angular/router';
import { AuthenticationService } from '../authentication/authentication.service';

@Injectable()
export class AuthGuardService implements CanActivate, CanActivateChild, CanLoad{

  constructor(private router : Router, private auth: AuthenticationService) { }

  canActivate(){
    if(this.auth.rememberedMe()){
      var res = this.auth.user!==undefined ? true : this.auth.getUserByRememberMe().then((res : boolean) => res)
      this.router.navigate(['/']);
      return res && this.auth.loggedIn();
    }
    else if(this.auth.loggedIn()){
      if( this.auth.getRole('contratista') ){
        this.router.navigate(['inspecciones'])
      }
      return this.auth.user!==undefined ? true : this.auth.getUserByToken().then((res : boolean) => {
        return res
      });
    }

    this.router.navigate(['/login']);
    return false;
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot){
  	return this.canActivate() && this.checkHaveRole(state.url);
  }

  canLoad(){
    return this.canActivate();
  }

  private checkHaveRole(url){
    switch (true) {
      case url.match(/^\/$/):
        return true;
      case url.match(/^\/upload[\/.*]?/)!==null:
        return this.auth.getRole('admin');
    }
  }

  private checkHavePermission(url){
    switch (true) {
      case url.match(/^\/$/):
        return true;
      case url.match(/^\/upload[\/.*]?/)!==null:
        return this.auth.getPermission('admin');
    }
  }

  public canLoadSidebar(){
    return this.auth.getPermission('admin');
  }

}
