export function dtGetQuery(params) {
  // debugger;
  let str = "?";
  // Parametros de nivel 0
  str += ( `draw=${ params.draw }&length=${ params.length }&start=${ params.start }` );
  // columns
  Object.keys(params.columns).forEach(function (item) {

      // data
      str += ( `&columns[${ item }][data]=${ params.columns[item].data }` );
      // name
      str += ( `&columns[${ item }][name]=${ params.columns[item].name }` );
      // searchable
      str += ( `&columns[${ item }][searchable]=${ params.columns[item].searchable }` );
      // orderable
      str += ( `&columns[${ item }][orderable]=${ params.columns[item].orderable }` );
      // search value
      str += ( `&columns[${ item }][search][value]=${ params.columns[item].search.value }` );
      // search regex
      str += ( `&columns[${ item }][search][regex]=${ params.columns[item].search.regex }` );
  });

  // order
  Object.keys(params.order).forEach(function (item) {
      // column
      str += (`&order[${ item }][column]=${ params.order[item].column }`);
      // dir
      str += (`&order[${ item }][dir]=${ params.order[item].dir }`);
  });

  // search
  Object.keys(params.search).forEach(function (item) {
      // column
      str += (`&search[value]=${ params.search.value }`);
      // dir
      str += (`&search[regex]=${ params.search.regex }`);
  });

  // columns: [
  //     {
  //         data: "nombre",
  //         name: "",
  //         searchable: true,
  //         orderable: true,
  //         search: {
  //             value: "",
  //             regex: false
  //         } }, …]
  // draw: 1
  // length: 40
  // order: [{ column: 0, dir: "asc" }]
  // search: { value: "", regex: false }
  // start: 0
  return str;
}
