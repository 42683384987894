import { Component, OnInit } from '@angular/core';
import { AuthGuardService } from '../core/guard/auth-guard.service';

@Component({
    selector: 'app-layout',
    templateUrl: './layout.component.html',
    styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit {

    constructor(public auth: AuthGuardService) { 
        this.canViewSidebar = this.auth.canLoadSidebar();
    }

    public canViewSidebar:boolean;
    ngOnInit() {
    }

}
