
const Upload = {
    text: 'Upload',
    link: '/upload',
    icon: 'icon-cloud-upload'
};

const Maps = {
   text: 'Maps',
   link: '/map',
   icon: 'icon-map'
}

const Itinerarios = {
  text: 'Itinerarios',
  link: '/itinerarios',
  icon: 'icon-location-pin'
}


const headingMain = {
    text: 'Main Navigation',
    heading: true
};

const Inspecciones = {
  text: 'Inspecciones',
  icon: 'fa fa-eye',
  link: '/inspecciones'
}

const Config = {
  text: 'Configuración',
  icon: 'fa fa-cogs',
  link: '/configuracion'
}

const Dashboard = {
  text: 'Estadisticas',
  icon: 'fa fa-tachometer',
  link: '/estadisticas'
}

export const menu = [
    Upload,
    Maps,
    Itinerarios,
    Inspecciones,
    Config,
    Dashboard
];
