import { Injectable } from '@angular/core';
import { dtGetQuery } from 'app/shared/helpers/helpers';
import { Observable } from 'rxjs';
import { of } from 'rxjs/observable/of';
import { Inspeccion } from './classes/inspeccion';
import { _throw } from 'rxjs/observable/throw';
import { environment } from 'environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class InspeccionService {
  getFichero(fileName: any): Observable<any> {

    let applicationsUrl = `${ environment.api_url }getFichero/${ fileName }`;
    return new Observable(observer=>{
      let req = new XMLHttpRequest();
      req.open('get',applicationsUrl);
      req.responseType = "arraybuffer";
      req.setRequestHeader("Authorization", "Bearer "+localStorage.getItem('id_token'));
      req.onreadystatechange = function() {
        if (req.readyState == 4 && req.status == 200) {
          observer.next(req.response);
          observer.complete();
        }

        if(req.readyState == 4 && req.status != 200){

          observer.error('e');
          observer.complete();
        }
      };
      req.send();
     });
  }
  nuevoDownloader(selectorTipo: number, formato: any, desde: any, hasta: any, periodicidad: number, separadoContratista: boolean): Observable<any>{
    return this.authHttp.get( `${ environment.api_url }inspecciones/download/${ selectorTipo }/${ formato }/${ desde }/${ hasta }/${ periodicidad }/${ separadoContratista }` );
  }

  guardarContratista(id, fotos: any, fecha, descripcion) {
    return this.authHttp.post( `${ environment.api_url }inspecciones/guardarContratista/${ id }`, {
      fotos,
      fecha,
      descripcion
    } );
  }

  exportarPdf( tipo, desdeFecha, hastaFecha ) {
    let applicationsUrl = `${environment.api_url}print/${ tipo }/${ desdeFecha }/${ hastaFecha }`;
      return Observable.create(observer=>{
        let req = new XMLHttpRequest();
        req.open('get',applicationsUrl);
        req.responseType = "arraybuffer";
        req.setRequestHeader("Authorization", "Bearer "+localStorage.getItem('id_token'));
        req.onreadystatechange = function() {
          if (req.readyState == 4 && req.status == 200) {
            observer.next(req.response);
            observer.complete();
          }

          if(req.readyState == 4 && req.status != 200){

            observer.error('e');
            observer.complete();
          }
        };
        req.send();
       });
  }

  exportarPdfByMes( tipo, anyo, mes ) {
    let applicationsUrl = `${environment.api_url}printByMes/${ tipo }/${ anyo }/${ mes }`;
      return Observable.create(observer=>{
        let req = new XMLHttpRequest();
        req.open('get',applicationsUrl);
        req.responseType = "arraybuffer";
        req.setRequestHeader("Authorization", "Bearer "+localStorage.getItem('id_token'));
        req.onreadystatechange = function() {
          if (req.readyState == 4 && req.status == 200) {
            observer.next(req.response);
            observer.complete();
          }

          if(req.readyState == 4 && req.status != 200){

            observer.error('e');
            observer.complete();
          }
        };
        req.send();
       });
  }

  switchValidado(id: any) {
    return this.authHttp.patch( `${ environment.api_url }inspecciones/switchValidado/${ id }`, id );
  }

  constructor(private authHttp: HttpClient) { }

  getItinerariosDatatable( parametros, tipo = 0, corregido = null ){

    const p = dtGetQuery( parametros );

    return this.authHttp.get( `${ environment.api_url }inspecciones/dt/${tipo}/${corregido}/${p}` );
  }

  getDataForIncidencia(){
    return this.authHttp.get( `${ environment.api_url }inspecciones/dataForIncidencias` );
  }

  crear( data: Inspeccion ){
    return this.authHttp.post( `${ environment.api_url }inspecciones`, data );
  }

  modificar( id, data: Inspeccion ){
    return this.authHttp.post( `${ environment.api_url }inspecciones/${ id }`, data );
  }

  subirFicheros( data: Inspeccion, respuesta: any ){
    let ficheros = [];
    let contador = 0;
    data.items.forEach( (item) => {
      item.imagenes.forEach( (imagen, idx) => {

        const foundItem = respuesta.inspeccion.items.find(
          (it) => {
            return item.tipo == it.item_tipable.tipable.id &&
            item.descripcion == it.descripcion &&
            item.valor == it.item_tipable.valor.id
          }
        );

        ficheros[contador] = {
          filename: imagen.filename,
          filetype: imagen.filetype,
          value: imagen.value,
          item_id: foundItem.id
        };
        contador+=1;
      });
    });
    if (ficheros.length) {
      return this.authHttp.post( `${ environment.api_url }inspecciones/ficheros`, ficheros );
    }else{
      return of({});
    }

  }

  actualizarFicheros( id, data: Inspeccion, respuesta: any ){
    let ficheros = [];
    let contador = 0;
    data.items.forEach( (item) => {
      item.imagenes.forEach( (imagen, idx) => {

        const foundItem = respuesta.inspeccion.items.find(
          (it) => {
            return item.tipo == it.item_tipable.tipable.id &&
            item.descripcion == it.descripcion &&
            item.valor == it.item_tipable.valor.id
          }
        );

        ficheros[contador] = {
          id: imagen.id,
          filename: imagen.filename,
          filetype: imagen.filetype,
          value: imagen.value,
          item_id: foundItem.id
        };
        contador+=1;
      });
    });
    if (ficheros.length) {
      return this.authHttp.post( `${ environment.api_url }inspecciones/ficheros/${ id }`, ficheros );
    }else{
      return of({});
    }

  }

  getImagenesRelacionadas( itinerario, tipo ){
    return this.authHttp.get( `${ environment.api_url }inspecciones/imagenesRelacionadas/${ itinerario }/${ tipo }` );
  }

  getInspeccion( id ){
    return this.authHttp.get( `${ environment.api_url }inspecciones/${ id }` );
  }

  borrar( id ){
    return this.authHttp.delete( `${ environment.api_url }inspecciones/${ id }` );
  }

  borrarFoto( id ){
    return this.authHttp.delete( `${ environment.api_url }inspecciones/foto/${ id }` )
  }
}
