import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'imagenesFiltradas'
})
export class ImagenesFiltradasPipe implements PipeTransform {

  transform( imagenes: any, tipo ): any {
    return imagenes.filter((e) => {return e.tipo == tipo} );
  }

}
