import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'whereSubtipo',
})
export class WhereSubtipoPipe implements PipeTransform {

  transform(value: any, planificados, id, ...args: any[]): any {
    let res = planificados.find( e => e.id == id );
    return res != undefined ? res.subtipos : [];
  }

}
