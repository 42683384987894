import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs';

@Injectable()
export class BotonesMapsService {

  public $datosCercanos = new Subject<number>();
  public $changeMap = new BehaviorSubject<number>(1);
  public $changeRadio = new BehaviorSubject<number>(300);

  public datosCercanos(){
    return this.$datosCercanos.asObservable();
  }

  public changeMap(){
    return this.$changeMap.asObservable();
  }

  public datosCercanosClicados( radio ){
    this.$datosCercanos.next(radio);
  }

  public changeRadio(){
    return this.$changeRadio.asObservable();
  }

  public cambioRadio( radio ){
    this.$changeRadio.next(radio);
  }

  public changeMapClicado( tipo ){
    this.$changeMap.next( tipo );
  }

  constructor() {

  }

}
