import { Component, OnInit, ViewChild } from '@angular/core';
import { Lightbox } from 'ngx-lightbox';
import { NgxSpinnerService } from 'ngx-spinner';
import { InspeccionService } from '../../inspeccion.service';

import 'jquery';
import 'bootstrap';
import { AuthenticationService } from 'app/core/authentication/authentication.service';
import { ToasterService } from 'angular2-toaster';
import { BsModalComponent } from 'ng2-bs3-modal';
@Component({
  selector: 'app-modal-ver',
  templateUrl: './modal-ver.component.html',
  styleUrls: ['./modal-ver.component.scss']
})
export class ModalVerComponent implements OnInit {


  public inspeccion;
  public fecha;
  public descripcion;

  public esContratista;

  @ViewChild(BsModalComponent)
    modal: BsModalComponent;

  constructor( private is: InspeccionService, private _lightbox: Lightbox,
    private spin: NgxSpinnerService,
    private auth: AuthenticationService,
    private toasterService: ToasterService
  ) {
    this.esContratista = this.auth.getRole('contratista');
  }

  ngOnInit() {
    document.getElementsByTagName('footer')[0].style.display = 'none';
  }

  public open( id ){
    this.spin.show();

    this.is.getInspeccion( id ).subscribe( ( res: any ) => {
      this.spin.hide();
      this.inspeccion = res.inspeccion;
      this.modal.open();
    });
  }

  abrir_imagen = ( img_idx, imagenes, contratista ) => {
    const img = imagenes.map( (el) => {
      return {
        id: el.id,
        src: el.data,
        caption: '',
        thumb: null,
        contratista: el.contratista
      }
    }).filter( ( el ) => {
      return el.contratista == contratista;
    } );
    this._lightbox.open( img, img_idx );
  }

  eliminarFichero = ( idx_item, idx_imagen ) => {
    let real_index = 0,
        counter = 0;
    this.inspeccion.items[idx_item].fotos.forEach((element, idx, arr) => {
      if(element.contratista == 1){
        if(counter == idx_imagen){
          real_index = idx;
        }
        counter += 1;
      }
    });

    this.inspeccion.items[idx_item].fotos.splice(real_index, 1);
  }

  anyadirImagen(item) {

    this.inspeccion.items[item].fotos.push( {
      contratista: 1
    } );
    let len = this.inspeccion.items[item].fotos.filter(
      e => {
        return e.contratista == 1;
      }
    ).length;

    setTimeout( () => {
      let element: HTMLElement = document.getElementById(`img_item_${ item }_${ len - 1 }`) as HTMLElement;
      element.click();
    }, 50);
  }

  onFileChange( event, item_idx, foto_idx){

    let file = event.target.files[0];

    let ultimaImagen = this.inspeccion.items[item_idx].fotos.length - 1;

    let reader = new FileReader();
    reader.readAsDataURL(file);


    reader.onload = (r) => {
      this.inspeccion.items[item_idx].fotos[ultimaImagen] = {
        filename: file.name,
        filetype: file.type,
        value: reader.result.split(',')[1],
        src: reader.result,
        data: reader.result,
        item_id: '',
        contratista: 1
      }

    };
  }
  guardar( idx ){
    this.spin.show();
    this.is.guardarContratista(this.inspeccion.items[idx].id, this.inspeccion.items[idx].fotos.filter((e) => e.contratista == 1).map( ( e ) => {
      return {
        src: e.src,
        filename: e.filename,
        filetype: e.filetype,
        contratista: e.contratista
      }
    }), this.inspeccion.items[idx].fecha_resolucion, this.inspeccion.items[idx].descripcion_contratista ).subscribe(
      ( res: any ) => {
        this.spin.hide();
        this.toasterService.pop( 'success', 'S\'ha guardat correctament');
      },
      ( err )  => {
        this.spin.hide();
        this.toasterService.pop( 'error', 'Error al guardar');
      }
    )
  }
}
