import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ToasterService } from 'angular2-toaster/angular2-toaster';

import { AuthenticationService } from '../../core/authentication/authentication.service';
import { SettingsService } from '../../core/settings/settings.service';
import { Credentials } from './login';
import { NgxSpinnerService } from 'ngx-spinner';


@Component({
  selector: 'login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

	valForm: FormGroup;
	credentials: Credentials;

  	constructor(private auth : AuthenticationService, public settings: SettingsService, private fb: FormBuilder, public router : Router, public toasterService: ToasterService, private spin: NgxSpinnerService) {
      if(this.auth.loggedIn() || this.auth.rememberedMe()) this.router.navigate(['/']);
  		this.valForm = this.fb.group({
  		    'username': [null, Validators.compose([Validators.required])],
          'password': [null, Validators.required],
  		    'remember_me': [false],
  		});
  	}

	ngOnInit() : void {

    if(localStorage.getItem('id_token')!==undefined)
      this.auth.logout()
	}

  onLogin($ev, credentials) : boolean {
    let loggin = false;
    if ($ev !== null )$ev.preventDefault();
    for (let c in this.valForm.controls) {
      this.valForm.controls[c].markAsTouched();
    }

    if (this.valForm.valid) {

      this.spin.show();
      this.auth.login(credentials).subscribe((res : boolean) => {
        this.spin.hide();
        if(res) {
          loggin = true;
          this.router.navigate(['/']);
        }
      },
      error => {
        this.spin.hide();
        this.toasterService.pop("error", "Login incorrecte");
      });
    }
    return loggin;
  }

}
