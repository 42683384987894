import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filtroInspeccionesTipo',
})
export class FiltroInspeccionesTipoPipe implements PipeTransform {

  transform(value: any, tipo): any {
    return value.filter((e) => e.tipo_inspeccion_id == tipo);
  }

}
