import { ErrorHandler, Injectable, NgZone } from "@angular/core";
import { AuthenticationService } from "../authentication/authentication.service";
import { ErrorReport } from "./error-report";
import { ErrorService } from "./error.service";

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  constructor(
     private zone: NgZone,
     private auth: AuthenticationService,
     private errorService: ErrorService) {}

  handleError(error: Error) {

    let reporte = new ErrorReport();
    reporte.user = this.auth.user;
    reporte.message = error.message;
    reporte.stack = error.stack;
    reporte.errname = error.name;
    reporte.navigator = JSON.stringify({
      appCodeName: navigator.appCodeName,
      appName: navigator.appName,
      appVersion: navigator.appVersion,
      hardwareConcurrency: navigator.hardwareConcurrency,
      language: navigator.language,
      maxTouchPoints: navigator.maxTouchPoints,
      onLine: navigator.onLine,
      platform: navigator.platform,
      product: navigator.product,
      productSub: navigator.productSub,
      userAgent: navigator.userAgent,
      vendor: navigator.vendor,
      vendorSub: navigator.vendorSub
    });
    reporte.page = window.location.href;


    this.errorService.saveError( reporte ).subscribe(( r ) => {}, console.error );

    this.zone.run(() => {
      console.error("ERROR");
      console.error(error.message || "Undefined client error");

    });

    console.error("Error from global error handler", error);

  }
}
