import { Injectable } from '@angular/core';
import 'rxjs/add/operator/map';

import { Observable } from 'rxjs';
import 'rxjs/add/operator/toPromise';
import { JwtHelperService } from 'angular2-jwt';

import { SettingsService } from '../settings/settings.service';
import { User } from '../../routes/users/user';
import { Permission } from '../../routes/users/permission';
import { Role } from '../../routes/users/role';
import { environment } from 'environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class AuthenticationService {

        public user : User;
        public permissions : Array<Permission>;
        public roles : Array<Role>;

	constructor(private http: HttpClient, private settings: SettingsService, private jwtHelper: JwtHelperService) {}

  	login(credentials) :  Observable<boolean>{
		return this.http.post(environment.api_url+'login', credentials)
			.map((res: any) => {
				let token = res && res.token;
				if(token){
          localStorage.setItem('id_token', res.token);
          if(res.rememberToken !== undefined)
					  localStorage.setItem('remember_token', res.rememberToken);
					this.settings.setUserSetting('name', res.user.username);
                                         this.user = res.user;
                                         this.permissions = res.user.permissions;
                                         this.roles = res.user.roles;
					return true;
				}
				else{
					return false;
				}
			});
		/*.subscribe(
		  	// We're assuming the response will be an object
		  	// with the JWT on an id_token key
		  	data => localStorage.setItem('id_token', data.token),
  			error => console.log(error)
        );*/
    }

    loggedIn() : boolean {
  		return !this.jwtHelper.isTokenExpired();
	  }

    rememberedMe(): boolean{
      return localStorage.getItem('remember_token') !== null;
    }

    logout() : Promise<boolean> {
		 return this.http.get(environment.api_url+'logout')
			 .toPromise()
			 .then((res: any) => {
                               localStorage.removeItem('id_token');
                               localStorage.removeItem('remember_token');
                               return res.logout;
                            }).catch((ex) => {return false;});
    }

    getUserByToken() : Promise<boolean> {
      return this.http.get<boolean>(environment.api_url+'user/getByToken')
        .toPromise()
        .then((res : any) => {
          this.settings.setUserSetting('name', res.user.username);
          this.user = res;
          this.permissions = res.user.permissions;
          this.roles = res.user.roles;
          return true;
        }).catch((ex) => {
          throw new Error(ex);
          return false;});

    }
    getUserByRememberMe() : Promise<boolean> {
      return this.http.post<boolean>(environment.api_url+'user/getByRememberMe', {remember_token: localStorage.getItem('remember_token')})
        .toPromise()
        .then((res : any) => {

          this.settings.setUserSetting('name', res.user.username);
          this.user = res;
          this.permissions = res.user.permissions;
          this.roles = res.user.roles;
          localStorage.setItem('id_token', res.token);
          return true;
        }).catch((ex) => {return false;});

    }

    getPermission(perm){
       let permissions = this.getArrayFrom(perm);
       for (let i = 0; i < permissions.length; i++) {
          if(this.permissions == undefined) return true;
          for (let j = 0; j < this.permissions.length; j++) {
             if(this.permissions[j].slug == permissions[i] ){
                 return true
             }
          }
       }
       return false;
    }

    getRole(role){
       let r = this.getArrayFrom(role);
       try{
         for (let i = 0; i < r.length; i++) {
            for (let j = 0; j < this.roles.length; j++) {
               if(this.roles[j].slug == r[i] ){
                   return true
               }
            }
         }
       }catch( e ){}
       return false;
    }

    private getArrayFrom(argument){
       return argument.indexOf("|")!==-1 ? argument.split("|") : [argument];
    }

}
