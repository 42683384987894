import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from 'app/core/authentication/authentication.service';
import config from 'app/core/config/config';
import { BotonesMapsService } from 'app/routes/maps/botones-maps.service';
import { SettingsService } from '../../core/settings/settings.service';

@Component({
  selector: '[app-footer]',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {

  // public radioCirculo = 300;
  // public tipoMapa;
  // public markerCircle: {lat:number, lng: number} = {
  //   lat: config.default_lat,
  //   lng: config.default_lon
  // }
  // constructor( public settings: SettingsService, private auth: AuthenticationService, public router : Router, private bs: BotonesMapsService ) { }

  // ngOnInit() {

  // }

  // changeMap( tipo ){
  //   this.tipoMapa = tipo;
  //   var clase = (tipo == 1) ? 'danger' : (tipo == 2 )? 'info' : (tipo == 3) ? 'warning' : 'success';
  //   $('button.btn').addClass('no-active');
  //   $('button.btn-'+clase).removeClass('no-active');
  //   this.bs.changeMapClicado(tipo);
  // }

  // /**
  // * Cierre de sesión
  // */
  // public logout() {
  //   this.auth.logout()
  //   .then((logout :boolean) => {
  //     this.router.navigate(['login']);
  //   });
  // }

  // verDatosCercanos(){
  //   this.bs.datosCercanosClicados( this.radioCirculo );
  // }

  // /**
  // * Acciones realizadas cuando carga la vista
  // */
  // ngAfterViewInit(){
  //   this.changeMap(1);
  // }

  // cambioRadio(){
  //   this.bs.cambioRadio(this.radioCirculo);
  // }
}
