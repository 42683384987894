import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fotosFiltradas',
  pure: false
})
export class FotosFiltradasPipe implements PipeTransform {

  transform(value: any, tipo, ...args: any[]): any {
    return value.filter(
      ( e ) => {
        return e.contratista == tipo;
      }
    );;

  }

}
