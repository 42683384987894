import { User } from "app/routes/users/user";

export class ErrorReport{
  user: User;
  message: string;
  errname: string;
  stack: string;
  clientTime: string = `${ new Date().getFullYear() }-${ ( new Date().getMonth() + 1 ) > 12 ? 1 : new Date().getMonth() + 1 }-${ new Date().getDate() } ${ new Date().getHours() }:${ new Date().getMinutes() }:${ new Date().getSeconds() }` ; // Y-m-d H:i:s
  navigator: string;
  page: string;
}
