import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';


import { environment } from 'environments/environment';
import { ErrorReport } from './error-report';

@Injectable()
export class ErrorService {

  private base_url = environment.api_url + '/errorHandler';
	constructor(private http: HttpClient) {}

  public saveError( report: ErrorReport ){
    return this.http.post( this.base_url, report );
  }

}
