import { EventEmitter, Injectable } from '@angular/core';

@Injectable()
export class BuscadorService {

  private _busqueda: EventEmitter<Object> = new EventEmitter<Object>();

  public getBusqueda(){
    return this._busqueda.asObservable();
  }

  public setBusqueda( punto ){
    this._busqueda.next( punto );
  }
  constructor() { }

}
