import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ToasterModule } from 'angular2-toaster/angular2-toaster';

import { AccordionModule } from 'ngx-bootstrap/accordion';
import { AlertModule } from 'ngx-bootstrap/alert';
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ModalModule } from 'ngx-bootstrap/modal';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { RatingModule } from 'ngx-bootstrap/rating';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { DatepickerModule } from 'ngx-bootstrap/datepicker';

import { FlotDirective } from './directives/flot/flot.directive';
import { SparklineDirective } from './directives/sparkline/sparkline.directive';
import { EasypiechartDirective } from './directives/easypiechart/easypiechart.directive';
import { ColorsService } from './colors/colors.service';
import { CheckallDirective } from './directives/checkall/checkall.directive';
import { VectormapDirective } from './directives/vectormap/vectormap.directive';
import { NowDirective } from './directives/now/now.directive';
import { ScrollableDirective } from './directives/scrollable/scrollable.directive';
import { JqcloudDirective } from './directives/jqcloud/jqcloud.directive';

import { DataTablesModule } from "angular-datatables";
import { Ng2WizardModule } from 'ng2-wizard';
import { ValoresFiltradosPipe } from 'app/routes/inspeccion/valores-filtrados.pipe';
import { FotosFiltradasPipe } from 'app/routes/inspeccion/fotos-filtradas.pipe';
import { ImagenesFiltradasPipe } from 'app/routes/inspeccion/imagenes-filtradas.pipe';
import { LightboxModule } from 'ngx-lightbox';
import { ModalVerComponent } from 'app/routes/inspeccion/lista-inspecciones/modal-ver/modal-ver.component';
import { WhereSubtipoPipe } from 'app/routes/inspeccion/where-subtipo.pipe';
import { FiltroInspeccionesTipoPipe } from 'app/routes/parque-modal/filtro-inspecciones-tipo.pipe';
import { BsModalModule } from 'ng2-bs3-modal';

// https://angular.io/styleguide#!#04-10
@NgModule({
    imports: [
      CommonModule,
      FormsModule,
      ReactiveFormsModule,
      AccordionModule.forRoot(),
      AlertModule.forRoot(),
      ButtonsModule.forRoot(),
      CarouselModule.forRoot(),
      CollapseModule.forRoot(),
      DatepickerModule.forRoot(),
      BsDropdownModule.forRoot(),
      ModalModule.forRoot(),
      PaginationModule.forRoot(),
      ProgressbarModule.forRoot(),
      RatingModule.forRoot(),
      TabsModule.forRoot(),
      TimepickerModule.forRoot(),
      TooltipModule.forRoot(),
      TypeaheadModule.forRoot(),
      ToasterModule,
      DataTablesModule,
      BsModalModule,
      Ng2WizardModule,
      DatepickerModule,
      LightboxModule
    ],
    providers: [
      ColorsService,
    ],
    declarations: [
      ModalVerComponent,
      FlotDirective,
      SparklineDirective,
      EasypiechartDirective,
      CheckallDirective,
      VectormapDirective,
      NowDirective,
      ScrollableDirective,
      JqcloudDirective,
      // AnalisisModalComponent,
      FotosFiltradasPipe,
      ValoresFiltradosPipe,
      ImagenesFiltradasPipe,
      WhereSubtipoPipe,
      FiltroInspeccionesTipoPipe,
    ],
    exports: [
      ModalVerComponent,
      CommonModule,
      FormsModule,
      ReactiveFormsModule,
      RouterModule,
      AccordionModule,
      AlertModule,
      ButtonsModule,
      CarouselModule,
      CollapseModule,
      DatepickerModule,
      BsDropdownModule,
      ModalModule,
      PaginationModule,
      ProgressbarModule,
      RatingModule,
      TabsModule,
      TimepickerModule,
      TooltipModule,
      TypeaheadModule,
      ToasterModule,
      FlotDirective,
      SparklineDirective,
      EasypiechartDirective,
      CheckallDirective,
      VectormapDirective,
      NowDirective,
      ScrollableDirective,
      JqcloudDirective,
      DataTablesModule,
      // AnalisisModalComponent,
      Ng2WizardModule,
      BsModalModule,
      ValoresFiltradosPipe,
      WhereSubtipoPipe,
      FotosFiltradasPipe,
      ImagenesFiltradasPipe,
      FiltroInspeccionesTipoPipe,
      LightboxModule,
    ]
})

// https://github.com/ocombe/ng2-translate/issues/209
export class SharedModule {
    static forRoot(): ModuleWithProviders {
        return {
            ngModule: SharedModule
        };
    }
}
