import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { JwtModule } from 'angular2-jwt';
import { environment } from 'environments/environment';

export function tokenGetter() {
  return localStorage.getItem("id_token");
}

@NgModule({
  imports: [
    HttpClientModule,
    JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetter,
        whitelistedDomains: ['jocs.local', 'apijocs.strengths.es'],
        blacklistedRoutes: [ '.*/login']
      }
    })
  ],
})
export class AuthModule {}
