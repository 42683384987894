import { EventEmitter, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Punto } from './punto';

import { environment } from 'environments/environment';
import { HttpClient } from '@angular/common/http';
@Injectable()
export class PuntosService {

  private _puntos: EventEmitter<Array<any>> = new EventEmitter<Array<any>>();

  public setPuntosObservable( pt: Array<any> ){
    this._puntos.next( pt );
  }

  public getPuntosObservable(): Observable<Array<any>>{
    return this._puntos.asObservable();
  }

  constructor(private authHttp: HttpClient) { }

  getPuntos() : Promise<Punto[]>{
     return this.authHttp.get(environment.api_url+'puntos')
        .toPromise()
        .then((response : any) => {
          const pts = response.puntos;
          this.setPuntosObservable( pts );
          return pts;
        });
  }

}
