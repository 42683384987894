
import { LayoutComponent } from '../layout/layout.component';

import * as login from './login';
import { AuthGuardService } from '../core/guard/auth-guard.service'

export const routes = [

    { path : 'login' , component: login.LoginComponent },
    {
        path: '',
        component: LayoutComponent,
        canLoad: [AuthGuardService],
        loadChildren: './admin/admin.module#AdminModule',
        data: {
          preload: true
        }
    },


    // Not found
    { path: '**', redirectTo: '' }

];
