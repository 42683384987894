import { BrowserAnimationsModule } from '@angular/platform-browser/animations'; // this is needed!
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';

import { CoreModule } from './core/core.module';
import { LayoutModule } from './layout/layout.module';
import { SharedModule } from './shared/shared.module';
import { RoutesModule } from './routes/routes.module';

import { AuthModule } from './core/authentication/auth.module';
import { BotonesMapsService } from './routes/maps/botones-maps.service';
import { NgxSpinnerModule, NgxSpinnerService } from 'ngx-spinner';
import { ErrorHandlerModule } from './core/error/error-handler.module';


@NgModule({
    declarations: [
        AppComponent,
    ],
    imports: [
      NgxSpinnerModule,
      BrowserAnimationsModule, // required for ng2-tag-input
      CoreModule,
      AuthModule,
      LayoutModule,
      SharedModule.forRoot(),
      RoutesModule,
      ErrorHandlerModule

    ],
    exports: [
    ],
    providers: [
      BotonesMapsService,
      NgxSpinnerService
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
